const website = require("./website")

module.exports = {
  "en-de": {
    default: true,
    path: "en",
    locale: "en-de",
    siteLanguage: "en",
    ogLang: "en_US",
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    category: "Category",
    categories: "Categories",
    about: "About",
    contact: "Contact",
    was: "was",
    were: "were",
    tagged: "tagged with",
    recent: "Recent",
    projects: "projects",
    posts: "posts",
    allCategories: "All categories",
    entries: "entries",
  },
  "de-de": {
    path: "de",
    locale: "de-de",
    siteLanguage: "de",
    ogLang: "de_DE",
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription:
      "Basierend auf gatsby-starter-prismic mit Unterstützung für Lokalisierung (i18n)",
    headline: "Schreiben und Veröffentlichen für LekoArts",
    category: "Kategorie",
    categories: "Kategorien",
    about: "About",
    contact: "Kontakt",
    was: "wurde",
    were: "wurden",
    tagged: "markiert mit",
    recent: "Neue",
    projects: "Projekte",
    posts: "Posts",
    allCategories: "Alle Kategorien",
    entries: "Einträge",
  },
}
