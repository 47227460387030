const theme = {
  colors: {
    primary: "#07038C",
    bg: "#fff",
    black: "#000",
    greyLight: "#f2c858",
    greyBlue: "#a2bce2",
    grey: "#595C62",
    greyDark: "#303643",
    greyDarker: "#1c252b",
    darkBlue: "#07038C",
    midBlue: "#0439D9",
    lightBlue: "#056CF2",
    red: "#F20505",
    beige: "#f2c858",
  },
  maxWidth: "1000px",
  maxWidthText: "720px",
  breakpoints: {
    xs: "400px",
    s: "600px",
    m: "900px",
    l: "1200px",
  },
}

export default theme
