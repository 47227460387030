import React from "react"
import LocalizedLink from "./LocalizedLink"

const Logo = () => (
  <LocalizedLink to="/" aria-label="Back to Home">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 343 128">
      <defs />
      <g clipPath="url(#clip0)">
        <path
          fill="#07038C"
          d="M74.5385 6c6.4856 0 11.5384 5.2549 11.5384 12s-5.0528 12-11.5384 12C68.0528 30 63 24.7451 63 18S68.0528 6 74.5385 6z"
        />
        <path
          fill="#056CF2"
          d="M118 30.0004C118 36.7453 112.745 42 106 42c-6.7451 0-12-5.2547-12-11.9996 0-6.7448 5.2549-11.9995 12-11.9995 6.745-.0785 12 5.2547 12 11.9995z"
        />
        <path
          fill="#0439D9"
          d="M44.5381 118C38.0526 118 33 112.745 33 106c0-6.7451 5.0526-12 11.5381-12 6.4854 0 11.538 5.2549 11.538 12 .0754 6.745-5.0526 12-11.538 12z"
        />
        <path
          fill="#F20505"
          d="M7 84c0-6.7451 5.2549-12 12-12s12 5.2549 12 12-5.2549 12-12 12S7 90.7451 7 84z"
        />
        <path
          fill="#000"
          d="M55.7 57.2l5.8 14.7 5.8-14.7H73l-9.7 22.8999h-3.6L50 57.2h5.7zM82.8 74.3999c1.8 0 3.1 1.4 3.1 3.1 0 1.7-1.4 3.1-3.1 3.1-1.7 0-3.1-1.4-3.1-3.1-.1-1.7 1.3-3.1 3.1-3.1zM108.9 61.5999h-7.4V66h6.9v4.5h-6.9V80h-5.3V57.2h12.7v4.3999zM119.3 74.3999c1.8 0 3.1 1.4 3.1 3.1 0 1.7-1.4 3.1-3.1 3.1-1.7 0-3.1-1.4-3.1-3.1 0-1.7 1.4-3.1 3.1-3.1zM139 67.9l-7.1-10.7h6.4l3.8 6.5 3.8-6.5h6.4l-7.1 10.7 8.4 12.1999h-6.4l-5.1-7.9-5.1 7.9h-6.4L139 67.9zM164.8 74.3999c1.8 0 3.1 1.4 3.1 3.1 0 1.7-1.4 3.1-3.1 3.1-1.7 0-3.1-1.4-3.1-3.1 0-1.7 1.4-3.1 3.1-3.1zM201.7 57.2v15.6c0 1.7-.1 4.3-2 6.1-1.5 1.5-3.4 1.6999-4.8 1.6999-1.2 0-2.4-.0999-3.6-.7999-.7-.4-1.6-1.2-2.2-1.9l3.3-3.7001c.2.4.6 1.0001.8 1.3001.4.4.8.5 1.3.5.4 0 .9-.1 1.2-.5.7-.7001.7-1.8.7-2.7V57.2h5.3zM233.2 60c2.2 2.1 3.6 5.0999 3.6 8.6999 0 3.1-1.1 6.2001-3.6 8.6001-2.1 2.1-5 3.3999-8.9 3.3999-4.3 0-7.2-1.6999-8.9-3.3999-2.2-2.1-3.6-5.2-3.6-8.5s1.5-6.6001 3.5-8.7001c1.5-1.5 4.4-3.3999 9-3.3999 3.6 0 6.6 1.1 8.9 3.3zm-13.9 3.5c-1 1-2 2.5999-2 5.1999 0 2.1.7 3.8001 2.1 5.2001 1.5 1.4 3.1 1.9 4.9 1.9 2.3 0 4-.9 5.1-2 .9-.9 2-2.5001 2-5.1001 0-2.4-1-4.0999-2-5.1999-1.1-1.1-3-2-5-2-2.1 0-3.9.8-5.1 2zM252.2 65.9h9.1v-8.7h5.3v22.8999h-5.3V70.4h-9.1v9.6999h-5.3V57.2h5.3v8.7zM298.1 60c2.2 2.1 3.6 5.0999 3.6 8.6999 0 3.1-1.1 6.2001-3.6 8.6001-2.1 2.1-5 3.3999-8.9 3.3999-4.3 0-7.2-1.6999-8.9-3.3999-2.2-2.1-3.6-5.2-3.6-8.5s1.5-6.6001 3.5-8.7001c1.5-1.5 4.4-3.3999 9-3.3999 3.6 0 6.6 1.1 8.9 3.3zm-13.9 3.5c-1 1-2 2.5999-2 5.1999 0 2.1.7 3.8001 2.1 5.2001 1.5 1.4 3.1 1.9 4.9 1.9 2.3 0 4-.9 5.1-2 .9-.9 2-2.5001 2-5.1001 0-2.4-1-4.0999-2-5.1999-1.1-1.1-3-2-5-2-2.1 0-3.9.8-5.1 2zM313.9 57.2l4.9 15.2 4.9-15.2h3.6l4.9 15.2 4.9-15.2h5.5l-7.8 22.8999h-4.5l-4.8-13.9-4.8 13.9h-4.5L308.4 57.2h5.5z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h342.5v127.1H0z" />
        </clipPath>
      </defs>
    </svg>
  </LocalizedLink>
)

export default Logo
