module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "V.F.X. Johow - Code, Design & E-Commerce", // Navigation and Site Title
  titleAlt: "V.F.X. Johow", // Title for JSONLD
  description:
    "Code, Design, E-Commerce ~ Full Stack Javascript und Shopify Developement in München.",
  headline: "Code and design progressive web sites.", // Headline for schema.org JSONLD
  url: "https://www.vfxjohow.io", // Domain of your site. No trailing slash!
  logo: "/logos/vfxjohow-icon.png", // Used for SEO
  ogLanguage: "en_US", // Facebook Language

  // JSONLD / Manifest
  favicon: "src/favicon.png", // Used for manifest favicon generation
  shortName: "V.F.X. Johow", // shortname for manifest. MUST be shorter than 12 characters
  author: "V.F.X. Johow", // Author for schemaORGJSONLD
  themeColor: "#07038C",
  backgroundColor: "#ffffff",

  twitter: "@vfxjohow", // Twitter Username
  facebook: "vfxjohow", // Facebook Site Name
  googleAnalyticsID: "UA-XXXXXX-X",

  skipNavId: "reach-skip-nav", // ID for the "Skip to content" a11y feature
}
